import { Box, Chip, CircularProgress, ClickAwayListener, Grid, Grow, Paper, Popper, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { IconMessage } from "@tabler/icons-react";
import MainCard from "../../cards/MainCard";
import { __ } from "../../../misc/i18n";
import useTranslate from "../../../hooks/useTranslate";

const Helpdesk = () => {
    const [open, setOpen] = useState(false);

    const theme = useTheme();
    const anchorRef = useRef(null);
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const { __ } = useTranslate();

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (!open) {
            return;
        }

        const script = document.createElement('script');

        script.src = "https://nasza-stajnia.youtrack.cloud/static/simplified/form/form-entry.js";
        // script.async = true;
        script.id = "fcae2013-09e6-470f-bbbc-779e2f99b438";
        script.dataset.ytUrl = "https://nasza-stajnia.youtrack.cloud";
        script.dataset.theme = "light";
        script.dataset.lang = "pl";

        document.getElementById('yt-form')?.appendChild(script);

        return () => {
            document.getElementById('yt-form')?.removeChild(script);
        }
    }, [open]);

    console.log('Helpdesk rendered');

    return (
        <>
            <Box
                sx={{
                    mx: 2,
                    [theme.breakpoints.down('lg')]: {
                        mr: 2,
                    },
                }}
            >
                <Chip
                    sx={{
                        height: '48px',
                        alignItems: 'center',
                        borderRadius: '27px',
                        transition: 'all .2s ease-in-out',
                        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light,
                        backgroundColor:
                            theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light,
                        '&[aria-controls="menu-list-grow"], &:hover': {
                            borderColor: theme.palette.warning.dark,
                            background: `${theme.palette.warning.dark}!important`,
                            color: theme.palette.warning.light,
                            '& svg': {
                                stroke: theme.palette.warning.light,
                            },
                        },
                        paddingLeft: 1,
                        color: theme.palette.warning.dark,
                        [theme.breakpoints.down('md')]: {
                            width: '48px',
                            margin: 0,
                            padding: 0,

                            '& svg': {
                                margin: '0 !important',
                            },

                            '& .MuiChip-label': {
                                display: 'none',
                            }
                        },
                    }}
                    icon={<IconMessage stroke={1.5} size="24px" color={theme.palette.warning.dark} />}
                    label={__('Zgłoś błąd / pomysł lub zadaj pytanie')}
                    variant="outlined"
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                />
            </Box>
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [matchesXs ? 5 : 0, 20],
                        },
                    },
                ]}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Grow in={open} {...TransitionProps}>
                            <Paper>
                                {open && (
                                    <MainCard elevation={16}>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item xs={12}>
                                                <Box id="yt-form" />
                                                <Box id="yt-form-loader" display={'none'}>
                                                    <CircularProgress size={20} /> {__('Chwileczkę, wczytuje dane...')}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </MainCard>
                                )}
                            </Paper>
                        </Grow>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
}

export default Helpdesk;