import { MenuGroupType } from './types';
import { CalendarOutlined, ContactsOutlined } from '@ant-design/icons';

const icons = {
    ContactsOutlined,
    CalendarOutlined,
};

const client: MenuGroupType = {
    id: 'group-client',
    title: 'Klienci',
    type: 'group',
    children: [
        {
            id: 'clients',
            title: 'Klienci',
            type: 'item',
            url: '/clients',
            icon: icons.ContactsOutlined,
            breadcrumbs: false,
        },
        {
            id: 'schedule',
            title: 'Terminarz',
            type: 'item',
            url: '/schedule',
            icon: icons.CalendarOutlined,
            breadcrumbs: false,
        },
    ],
};

export default client;
