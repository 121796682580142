import { StyledEngineProvider, ThemeOptions, TypographyVariantsOptions } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import React, { useMemo } from 'react';
import Palette from './palette';
import Typography from './typography';
import customShadows from './shadows';
import componentStyleOverrides from './compStyleOverride';
import { CustomShadowProps } from '../types/default-theme';
import useConfig from '../hooks/useConfig';

export default function CustomTheme({ children }: React.PropsWithChildren) {
    const { borderRadius, fontFamily, mode, outlinedFilled, presetColor, themeDirection } = useConfig();

    console.log('fontFamily', fontFamily);

    const theme: Theme = useMemo<Theme>(() => Palette(mode, presetColor), [mode, presetColor]);

    const themeTypography: TypographyVariantsOptions = useMemo<TypographyVariantsOptions>(
        () => Typography(theme, borderRadius, fontFamily),
        [theme, borderRadius, fontFamily],
    );
    const themeCustomShadows: CustomShadowProps = useMemo<CustomShadowProps>(
        () => customShadows(mode, theme),
        [mode, theme],
    );

    const themeOptions: ThemeOptions = useMemo(
        () => ({
            direction: themeDirection,
            palette: theme.palette,
            mixins: {
                toolbar: {
                    minHeight: '48px',
                    padding: '16px',
                    '@media (min-width: 600px)': {
                        minHeight: '48px',
                    },
                },
            },
            typography: themeTypography,
            customShadows: themeCustomShadows,
        }),
        [themeDirection, theme, themeCustomShadows, themeTypography],
    );

    const themes: Theme = createTheme(themeOptions);
    themes.components = useMemo(
        () => componentStyleOverrides(themes, borderRadius, outlinedFilled),
        [themes, borderRadius, outlinedFilled],
    );

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes}>
                <CssBaseline enableColorScheme />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
