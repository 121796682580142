import { DollarOutlined, SettingOutlined, SafetyOutlined } from '@ant-design/icons';
import { MenuGroupType } from './types.ts';
import { __ } from '../misc/i18n.tsx';
import { ACTIONS } from '../controllers/Permissions/consts.ts';

const icons = {
    DollarOutlined,
    SettingOutlined,
    SafetyOutlined,
};

const settings: MenuGroupType = {
    id: 'group-settings',
    title: __('Ustawienia'),
    type: 'group',
    children: [
        {
            id: 'stable',
            title: __('Stajnia'),
            type: 'item',
            url: '/settings/stable',
            icon: icons.SettingOutlined,
            breadcrumbs: false,
            permission: ACTIONS.UPDATE_STABLE,
        },
        {
            id: 'permissions',
            title: __('Uprawnienia'),
            type: 'item',
            url: '/settings/permissions',
            icon: icons.SafetyOutlined,
            breadcrumbs: false,
            permission: ACTIONS.UPDATE_STABLE,
        },
        {
            id: 'billing',
            title: __('Plan i rozliczenie'),
            type: 'item',
            url: '/settings/billing',
            icon: icons.DollarOutlined,
            breadcrumbs: false,
            // permission: ACTIONS.MENAGE_SUBSCRIPTION,
            permission: 'wczesny-dostep',
        },
    ],
};

export default settings;
