import { NavLink, useLocation } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';
import { MenuChildType } from '../../../../../menu-items/types.ts';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store.ts';
import { setDrawerOpenState } from '../../../../../slices/config.slice.ts';
import { usePermissionsGuard } from '../../../../../controllers/Permissions/index.ts';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

const NavItem = ({ item, level }: { item: MenuChildType; level: number }) => {
    const theme = useTheme();
    const drawerOpen = useSelector((state: RootState) => state.config.drawerOpen);
    const location = useLocation();
    const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
    const dispatch = useDispatch();
    const { checkPermission } = usePermissionsGuard();

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    const Icon = item.icon as React.ElementType;
    const itemIcon = Icon ? <Icon size={20} strokeWidth={1.6} style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} /> : false;

    if (item.permission && !checkPermission(item.permission)) {
        return null;
    }

    return (
        <ListItemButton
            component={NavLink}
            to={item.url}
            target={itemTarget}
            disabled={item.disabled}
            sx={{
                borderRadius: `5px`,
                mb: 0,
                alignItems: 'flex-start',
                backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                py: 1,
                pl: 2,
                mx: 2,
            }}
            selected={
                (location.pathname.includes(item.url) && item.url !== '/') ||
                (location.pathname === '/' && item.url === '/')
            }
            onClick={() => {
                if (matchDownLG) {
                    dispatch(setDrawerOpenState(!drawerOpen));
                }
            }}
        >
            <ListItemIcon
                sx={{
                    my: 'auto',
                    minWidth: !item?.icon ? 18 : 36,
                }}
            >
                {itemIcon}
            </ListItemIcon>

            <ListItemText
                primary={
                    <Typography variant={'body1'} color="inherit">
                        {item.title}
                    </Typography>
                }
                secondary={
                    item.caption && (
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        <Typography
                            variant="caption"
                            sx={{ ...theme.typography.subMenuCaption }}
                            display="block"
                            gutterBottom
                        >
                            {item.caption}
                        </Typography>
                    )
                }
            />

            {item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )}
        </ListItemButton>
    );
};

export default NavItem;
