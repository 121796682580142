import { Typography } from '@mui/material';

interface Props {
    justify?: 'center' | 'end' | 'start';
    stableName?: string;
}

const Logo = ({ justify = 'start', stableName }: Props) => {
    return (
        <Typography
            variant="h2"
            noWrap
            component="a"
            href="/"
            sx={{
                mr: 2,
                display: { xs: 'flex' },
                flexGrow: 0,
                fontWeight: 800,
                letterSpacing: '0.1rem',
                color: 'grey.700',
                textDecoration: 'none',
                justifyContent: justify,
                textShadow: '3px 3px 0px rgba(0, 0, 0, 0.1)',
            }}
        >
            {stableName || 'NaszaStajnia'}
        </Typography>
    );
};

export default Logo;
