import { Box, Chip } from "@mui/material";
import CHANGELOG from '../../../../../../CHANGELOG.md';

const extractLatestVersion = (changelog: string) => {
    const versionRegex = /## \[(\d+\.\d+\.\d+)\]/g;
    let match;
    const versions = [];

    while ((match = versionRegex.exec(changelog)) !== null) {
        versions.push(match[1]);
    }

    versions.sort((a, b) => {
        const aParts = a.split('.').map(Number);
        const bParts = b.split('.').map(Number);

        for (let i = 0; i < 3; i++) {
            if (aParts[i] > bParts[i]) return -1;
            if (aParts[i] < bParts[i]) return 1;
        }
        return 0;
    });

    return versions[0];
}

const AppVersion = () => {
    return (
        <Box mt={1}>
            <Chip
                label={'v' + extractLatestVersion(CHANGELOG)}
                size="small"
                sx={{
                    height: 18,
                    '& .MuiChip-label': { fontSize: '0.625rem', py: 0.25 },
                }}
                component="span"
            />
        </Box>
    );
}

export default AppVersion;