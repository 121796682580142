import axios, { AxiosRequestConfig } from 'axios';
import { UserEntity } from '../entities/UserEntity';
import { CollectionEntity } from '../entities/ResponseEntity';
import { PaginationProps } from '../misc/types.ts';
import { Dayjs } from 'dayjs';

export const getUsersService = ({ pageSize = 10, page = 0 }: PaginationProps, options?: AxiosRequestConfig) => {
    return axios.get<CollectionEntity<UserEntity>>(`/users?limit=${pageSize}&page=${page + 1}`, options);
};

export const getCurrentUserService = (options?: AxiosRequestConfig) => {
    return axios.get<UserEntity>('/users/current', options);
};

export const getUserByIDService = (userID: string, options?: AxiosRequestConfig) => {
    return axios.get<UserEntity>(`/users/${userID}`, options);
};

export const updateUserService = (userID: string, formData: Partial<UserEntity>) => {
    return axios.patch<UserEntity>(`/users/${userID}`, formData);
};

export const registerService = (formData: Partial<UserEntity>) => {
    return axios.post<UserEntity>(`/auth/register`, formData);
};

export const addUserService = (formData: Partial<UserEntity>) => {
    return axios.post<UserEntity>(`/users`, formData);
};

export const deleteUserService = (userID: string) => {
    return axios.delete(`/users/${userID}`);
};

export const deleteCurrentUserService = () => {
    return axios.delete('/users/current');
};

export const getInstructorLoadStatisticsService = async (from: Dayjs, to: Dayjs, options?: AxiosRequestConfig) => {
    const response = await axios.get<Array<{ time: number; trainer: Partial<UserEntity> }>>(`/statistics/instructor/load?from=${from}&to=${to}`, options);

    return response.data;
};