import Loadable from '../components/Loadable';
import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

const LazyLogin = Loadable(lazy(() => import('../pages/Login')));
const LazyRegister = Loadable(lazy(() => import('../pages/Register')));
const LazyRegisterDone = Loadable(lazy(() => import('../pages/RegisterDone')));
const LazyVerifyEmail = Loadable(lazy(() => import('../pages/VerifyEmail')));
const LazyForgotPassword = Loadable(lazy(() => import('../pages/ForgotPassword')));
const LazyResetPassword = Loadable(lazy(() => import('../pages/ResetPassword')));
const LazyInvitation = Loadable(lazy(() => import('../pages/Invitation')));

const AuthenticationRoutes = {
    path: '/',
    element: <><Outlet /></>,
    children: [
        {
            path: '/login',
            element: <LazyLogin />,
        },
        {
            path: '/register',
            element: <LazyRegister />,
        },
        {
            path: '/register/:token',
            element: <LazyRegister />,
        },
        {
            path: '/register-done',
            element: <LazyRegisterDone />,
        },
        {
            path: '/verify-email/:token',
            element: <LazyVerifyEmail />,
        },
        {
            path: '/forgot-password',
            element: <LazyForgotPassword />,
        },
        {
            path: '/reset-password/:token',
            element: <LazyResetPassword />,
        },
        {
            path: '/invitation/:token',
            element: <LazyInvitation />,
        },
    ],
};

export default AuthenticationRoutes;
