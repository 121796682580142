import dashboard from './dashboard.ts';
import stable from './stable.ts';
import client from './client.ts';
import settings from './settings.ts';

const menuItems = {
    items: [dashboard, stable, client, settings],
};

export default menuItems;
