import Layout from '../components/Layout';
import Loadable from '../components/Loadable';
import { lazy } from 'react';
import RequireAuth from '../components/RequireAuth';

const LazySettingsBilling = Loadable(lazy(() => import('../pages/Settings/Billing')));
const LazySettingsPlanUpgrade = Loadable(lazy(() => import('../pages/Settings/Billing/Upgrade')));

const LazySettingsStable = Loadable(lazy(() => import('../pages/Settings/Stable')));
const LazySettingsPermissions = Loadable(lazy(() => import('../pages/Settings/Permissions')));

const Settings = {
    path: '/settings',
    element: (
        <RequireAuth>
            <Layout />
        </RequireAuth>
    ),
    children: [
        {
            path: '/settings/billing',
            element: <LazySettingsBilling />,
        },
        {
            path: '/settings/billing/upgrade',
            element: <LazySettingsPlanUpgrade />,
        },
        {
            path: '/settings/stable',
            element: <LazySettingsStable />,
        },
        {
            path: '/settings/permissions',
            element: <LazySettingsPermissions />,
        },
    ],
};

export default Settings;
