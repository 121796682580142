import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
import Logo from '../../../Logo/index.tsx';
import { useAppSelector } from '../../../../hooks.ts';

// ==============================|| DRAWER HEADER ||============================== //



const DrawerHeader = ({ open }: { open: boolean }) => {
    const { stable } = useAppSelector((state) => state.stable);
    const theme = useTheme();

    return (
        <DrawerHeaderStyled theme={theme} open={open}>
            <Stack direction="row" alignItems="center">
                <Logo stableName={stable?.name} />
            </Stack>
        </DrawerHeaderStyled>
    );
};

DrawerHeader.propTypes = {
    open: PropTypes.bool,
};

export default DrawerHeader;
