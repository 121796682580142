import { Grid, useMediaQuery, useTheme } from '@mui/material';
import LogoVertical from '../../../Logo/LogoVertical.tsx';
import SimpleBar from '../../../SimpleBar/SimpleBar.tsx';
import Navigation from './Navigation';
import AppVersion from './AppVersion/index.tsx';

const DrawerContent = () => {
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <Grid container spacing={2} flexDirection={'column'} wrap='nowrap' justifyContent={'space-between'} height={'calc(100vh - 80px)'}>
            <Grid item>
                <SimpleBar>
                    <Navigation />
                </SimpleBar>
            </Grid>
            <Grid item textAlign={'center'} display={!matchDownMD ? 'block' : 'none'}>
                <LogoVertical onlyLogo width={30} />
                <AppVersion />
            </Grid>
        </Grid>

    );
};

export default DrawerContent;
