import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import dayjs, { Dayjs } from 'dayjs';

interface DashboardSlice {
    selectedDate: {
        from: Dayjs;
        to: Dayjs;
    };
}

const initialState: DashboardSlice = {
    selectedDate: {
        from: dayjs().startOf('day'),
        to: dayjs().endOf('day'),
    },
};

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setSelectedDate: (state, action: PayloadAction<{ from: Dayjs; to: Dayjs }>) => {
            state.selectedDate = action.payload;
        },
    },
});

export const { setSelectedDate } = dashboardSlice.actions;

export default dashboardSlice.reducer;
