import ReactDOM from 'react-dom/client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { store } from './store';
import { Provider } from 'react-redux';
import App from './App';
import Theme from './themes/Theme.tsx';
import './config/axios';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import './assets/scss/style.scss';
import './misc/error-handler';
import { ToastContainer } from 'react-toastify';
import React from 'react';
import Locales from './components/Locales';
import { ConfigProvider } from './config/ConfigContext.tsx';
import router from './routes';

// google-fonts
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/700.css';

import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';

import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
                    <ConfigProvider>
                        <Theme>
                            <Locales>
                                <App>
                                    <RouterProvider router={router} />
                                    <ToastContainer />
                                </App>
                            </Locales>
                        </Theme>
                    </ConfigProvider>
                </Sentry.ErrorBoundary>
            </Provider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
