import { createBrowserRouter, Navigate } from 'react-router-dom';
import AuthenticationRoutes from './Authentications.tsx';
import Dashboard from './Dashboard.tsx';
import Users from './Users.tsx';
import Horses from './Horses.tsx';
import RidingSpots from './RidingSpots.tsx';
import Clients from './Clients.tsx';
import Schedule from './Schedule.tsx';
import Mail from './Mail.tsx';
import Settings from './Settings.tsx';

// ==============================|| ROUTING RENDER ||============================== //
const router = createBrowserRouter(
    [
        {
            path: '/',
            element: <Navigate to={'dashboard'} />,
            errorElement: <div>ERROR</div>,
        },

        AuthenticationRoutes,
        Dashboard,
        Users,
        Horses,
        RidingSpots,
        Clients,
        Schedule,
        Mail,
        Settings,

        {
            path: '/*',
            element: <div>404 - taka strona nie istnieje</div>,
        },
    ],
    {
        basename: import.meta.env.VITE_APP_BASE_NAME,
    },
);

export default router;
