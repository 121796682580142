import { useIntl } from "react-intl";

/**
 * @returns  {Function} __ - (text: string, data?: Record<string, any>) => string
 * 1. Tekst przesyłany do funkcji nie może zawierać kodu HTML
 * 2. Dane do tekstu przekazyje się poprzez 'text {example} text'
 */
const useTranslate = () => {
    const intl = useIntl();

    const __ = (text: string, data?: Record<string, any>): string => {
        return intl.formatMessage({ id: text, defaultMessage: text }, data);
    };

    return { __ };
};

export default useTranslate;