import Layout from '../components/Layout';
import { lazy } from 'react';
import Loadable from '../components/Loadable';
import RequireAuth from '../components/RequireAuth';

const LazyPanel = Loadable(lazy(() => import('../pages/Panel')));

const Dashboard = {
    path: '/dashboard',
    element: (
        <RequireAuth>
            <Layout />
        </RequireAuth>
    ),
    children: [
        {
            path: '/dashboard',
            element: <LazyPanel />,
        },
    ],
};

export default Dashboard;
